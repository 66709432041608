import React, {useContext, useEffect, useState} from 'react';
import styles from './css/ControlPanel.module.css';
import {PageSortProvider} from "../utilities/PageSortContext";
import {allDevices} from "../helpers/interfaces";
import {useTranslation} from "react-i18next";
import {Icons} from "../helpers/Icons";
import {generateRandomHiddenPassLen, handleSorting} from "../helpers/helperFunctions";
import {getMacOsDevices, getUserDevicePasswordByID, getAllDevices, getMacSecretKeyByID} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import { format } from 'date-fns/format';
import Search from "../utilities/Search";

function ControlPanel()
{
    const {accessToken, notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [allDevices, setAllDevices] = useState<allDevices[]>([]);
    const [filteredAllDevices ,setFilteredAllDevices] = useState<allDevices[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [sortOrder, setSortOrder] = useState<string>("asc");
    const [sortField, setSortField] = useState<string>("deviceName");
    const [statusFilter, setDevicesFilter] = useState<string>("all");

    const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [devicesIDsPassword, setDevicesIDsPassword] = useState<string[]>([]);
    const [devicesPassword, setDevicesPassword] = useState<{id: string, password: string}[]>([]);

    const [isSecretKeyHidden, setIsSecretKeyHidden] = useState<boolean>(true);
    const [devicesIDsSecretKey, setDevicesIDsSecretKey] = useState<string[]>([]);
    const [devicesSecretKeys, setDevicesSecretKeys] = useState<{id: string, secretKey: string}[]>([]);

    const [isPasswordFetching, setIsPasswordFetching] = useState<boolean>(false);
    const [isSecretKeyFetching, setIsSecretKeyFetching] = useState<boolean>(false);

    useEffect(() =>
    {
        (async () =>
        {
            const allDevices = await getAllDevices(accessToken, notificationDispatch);
            if(Array.isArray(allDevices)) setAllDevices(allDevices);

        })();
    }, []);

    useEffect(() =>
    {
        if(allDevices.length)
        {
            switch (statusFilter)
            {
                case "windows":
                    setFilteredAllDevices(allDevices.filter(item => item.operatingSystem.toLowerCase() === "windows"));
                    break;
                case "macOS":
                    setFilteredAllDevices(allDevices.filter(item => item.operatingSystem.toLowerCase() === "macos"));
                    break;
                default:
                    return setFilteredAllDevices(allDevices);
            }
        }

    }, [statusFilter, allDevices]);

    const handleGetPassword = async (deviceID: string) =>
    {
        setIsError(false)
        return atob(await getUserDevicePasswordByID(deviceID, accessToken, notificationDispatch, setIsError));
    }

    const handleGetSecretKey = async (deviceID: string) =>
    {
        return atob(await getMacSecretKeyByID(deviceID, accessToken, notificationDispatch));
    }

    const handleViewPassword = async (deviceID: string) =>
    {
        if(!devicesIDsPassword.includes(deviceID))
        {
            setDevicesIDsPassword(prev => [...prev, deviceID]);

            setIsPasswordFetching(true);
            const pass = await handleGetPassword(deviceID);
            setDevicesPassword(current => [...current, {id: deviceID, password: pass}]);
            setIsPasswordFetching(false);

        } else
        {
            setDevicesIDsPassword(devicesIDsPassword.filter(id => id !== deviceID));
            setDevicesPassword(devicesPassword.filter(entry => entry.id !== deviceID));
        }
    }

    const handleViewSecretKey = async (deviceID: string) =>
    {
        if(!devicesIDsSecretKey.includes(deviceID))
        {
            setDevicesIDsSecretKey(prev => [...prev, deviceID]);

            setIsSecretKeyFetching(true);
            const secretKey = await handleGetSecretKey(deviceID);
            setDevicesSecretKeys(current => [...current, {id: deviceID, secretKey: secretKey}]);
            setIsSecretKeyFetching(false);

        } else
        {
            setDevicesIDsSecretKey(devicesIDsSecretKey.filter(id => id !== deviceID));
            setDevicesSecretKeys(devicesSecretKeys.filter(entry => entry.id !== deviceID));
        }
    }

    return (
        <PageSortProvider>
            <div className={styles.container}>

                <div className={styles.filter_controls}>
                    Number of items: {filteredAllDevices.length}
                    <Search onChange={setSearchQuery} placeholder={t("app.search")}/>

                    <div className={styles.state_filter}>
                        {t("app.devices")}:
                        <select className={styles.items_controls} defaultValue={statusFilter}
                                onChange={e => setDevicesFilter(e.target.value)}>
                            <option value="all">{t("app.all")}</option>
                            <option value="windows">Windows</option>
                            <option value="macOS">MacOS</option>
                        </select>
                    </div>
                </div>

                <table>
                    <thead>
                    <tr>
                        <th>
                            <div onClick={() => {
                                handleSorting(filteredAllDevices, "deviceName", sortOrder, setAllDevices, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                Device
                                name{sortField === "deviceName" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th style={{flex: 2}}>
                            <div onClick={() => {
                                handleSorting(filteredAllDevices, "userPrincipalName", sortOrder, setAllDevices, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                User
                                name{sortField === "userPrincipalName" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => {
                                handleSorting(filteredAllDevices, "osVersion", sortOrder, setAllDevices, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                OS
                                Version{sortField === "osVersion" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => {
                                handleSorting(filteredAllDevices, "operatingSystem", sortOrder, setAllDevices, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                OS{sortField === "operatingSystem" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th style={{flex: 2}}>
                            <div onClick={() => {
                                handleSorting(filteredAllDevices, "resultMessage", sortOrder, setAllDevices, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                Secret
                                key{sortField === "resultMessage" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>Password</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredAllDevices.filter(item => searchQuery.length ? item.deviceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.userPrincipalName.toLowerCase().includes(searchQuery.toLowerCase()) : item)
                        .map((item, index) =>
                            <tr key={item.id}>
                                <td data-label="Device name">{item.deviceName}</td>
                                <td style={{flex: 2}} data-label="User name">{item.userPrincipalName}</td>
                                <td data-label="OS">{item.operatingSystem}</td>
                                <td data-label="OS Version">{item.osVersion}</td>
                                <td style={{flex: 2}} data-label="Secret Key">
                                    {item.operatingSystem.toLowerCase() === "macos" ?
                                        <div className={styles.hidden_values}>
                                            <>
                                                {!devicesIDsSecretKey.includes(item.id) ? "*************************************" :
                                                    !isSecretKeyFetching || devicesSecretKeys[devicesSecretKeys.findIndex(entry => entry.id === item.id)] !== undefined ?
                                                        devicesSecretKeys[devicesSecretKeys.findIndex(entry => entry.id === item.id)].secretKey : "Fetching..."}

                                                <button
                                                    title={isSecretKeyHidden ? t("app.showSecretKey") : t("app.hideSecretKey")}
                                                    onClick={() => handleViewSecretKey(item.id)}>
                                                    {devicesIDsPassword.includes(item.id) ? Icons.show : Icons.hide}</button>
                                            </>
                                        </div>
                                    : "Secret key available only for MacOS devices"}
                                </td>
                                <td data-label="Password">
                                    <div className={styles.hidden_values}>
                                        <>
                                            {!devicesIDsPassword.includes(item.id) ? "***************" :
                                                !isPasswordFetching || devicesPassword[devicesPassword.findIndex(entry => entry.id === item.id)] !== undefined ?
                                                    devicesPassword[devicesPassword.findIndex(entry => entry.id === item.id)].password : "Fetching..."}

                                            <button
                                                title={isPasswordHidden ? t("app.showPassword") : t("app.hidePassword")}
                                                onClick={() => handleViewPassword(item.id)}>
                                                {devicesIDsPassword.includes(item.id) ? Icons.show : Icons.hide}</button>
                                        </>
                                    </div>
                                </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </PageSortProvider>
    );
}

export default ControlPanel;
